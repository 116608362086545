@media (min-width: 400px){
  /*  Globals */
  section h3.title{
    font-size: 1.75rem;
  }
  /* Hero */
  .hero .slick-dots{
    bottom: 75px !important;
  }
}
@media (min-width: 576px){

}
@media (min-width: 768px){
  .burger{
    display: none;
  }
}
@media (min-width: 992px){
  /* Globals */
  .desktop{
    display: block;
  }
  .mobile{
    display: none;
  }
  section h2.title{
    font-size: 2.5rem;
  }
  section h3.title{
    font-size: 2.25rem;
  }
  /* Navbar */
  header{
    padding-top: 25px;
    background-color: transparent;
  }
  header.header-background{
    background-color: #a59f9a;
  }
  header .navbar-brand img{
    width: 179px;
  }
  .navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-collapse{
    padding-top: 0;
  }
  /* Hero */
  .hero{
    padding-top: 0;
  }
  .item-carousel img{
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
  }
  .slide-info{
    padding-top: 15rem;
    bottom: auto;
    top: 0;
    padding-bottom: 0;
  }
  .slide-info .heading-title{
    height: 182px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .slide-info .heading-title h1{
    font-size: 3.65rem;
    line-height: 1;
  }
  .slide-info .heading-description{
    padding-top: 60px;
    padding-bottom: 15px;
  }
  .slide-info .heading-description ul li{
    font-size: 16px;
  }
  .hero .slick-dots{
    bottom: 129px !important;
    padding-left: 175px !important;
    text-align: left !important;
  }
  .hero .slick-dots li{
    width: 10px !important;
    height: 10px !important;
    margin: 0 2px !important;
  }
  .hero .slick-dots li button:before{
    width: 10px !important;
    height: 10px !important;
    line-height: 10px !important;
    color: #ffffff !important;
  }
  /* La Empresa */
  .la-empresa{
    padding-top: 10rem;
    padding-bottom: 6rem;
  }
  .text-empresa{
    padding-right: 4rem;
  }
  .card-empresa{
    margin-top: -14.5rem;
  }
  .card-empresa .title{
    padding: 3rem 5.75rem;
  }

  /* Productos y Servicios */
  .content-works .handle-scroll{
    overflow: hidden;
  }
  .works{
    width: auto;
  }
  .content-works .underline p{
    display: inline-block;
    justify-content: center;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 0;
    background: #ffffff;
  }
  .content-works .col-lg-10.underline::after{
    bottom: 39%;
    width: calc(100% - 30px);
    left: 15px;
    height: 2px;
    background-color: #000000;
    z-index: -1;
  }
  .popup .close{
    right: 50px;
    top: 50px;
  }
  .popup .categories{
    flex-direction: column;
  }
  .popup .content-gallery{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
  .popup .slick-list{
    max-height: 80vh;
  }
  .popup .slick-slide{
    max-height: 80vh;
  }
  .popup .carousel-item .img-fluid{
    max-height: 80vh;
  }
  .popup .algo--body{
    padding-right: 45px;
  }
  .featured-phrase{
    background-position: center center;
  }
  .featured-phrase h4{
    font-size: 1.5rem;
  }

  /* Contacto */
  .content-form{
    margin-bottom: -8rem;
  }
  .content-form form{
    box-shadow: 1px 1px 8px rgb(0 0 0 / 30%);
  }
  /* Footer */
  footer{
    padding-top: 20rem;
    background-repeat: no-repeat;
    background-size: cover; 
  }
}
@media (min-width: 1200px){
  /* Globals */
  .container.medium{
    max-width: 1230px;
  }

}
@media (min-width: 1410px){
  /* Globals */
  .container, .container-sm, .container-md, .container-lg, .container-xl{
    max-width: 1180px;
  }
  .container.medium{
    max-width: 1300px;
  }
  /* Navbar */
  header .navbar-brand img{
    width: 210px;
  }
  /* Hero */
  .slide-info{
    padding-top: 18rem;
  }
  .slide-info .heading-title{
    height: 212px;
  }
  .slide-info .heading-title h1{
    font-size: 4.2rem;
    line-height: 1;
  }
  .hero .slick-dots{
    bottom: 100px !important;
    padding-left: 200px !important;
  }
  /* La Empresa */
  .text-empresa{
    padding-right: 6rem;
  }
}
@media (min-width: 1800px){
  /* Globals */
  .container, .container-sm, .container-md, .container-lg, .container-xl{
    max-width: 1500px;
  }
  .container.medium{
    max-width: 1675px;
  }
  section h2.title{
    font-size: 3.25rem;
  }
  section h3.title{
    font-size: 2.75rem;
  }
  p{
    font-size: 18px;
  }
  /* Navbar */
  header{
    padding-top: 40px;
  }
  header .navbar-brand img{
    width: 233px;
  }
  .navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 30px;
    padding-right: 30px;
  }
  /* Hero */
  .slide-info{
    padding-top: 23rem;
  }
  .slide-info .heading-title{
    height: 260px;
  }
  .slide-info .heading-title h1{
    font-size: 5.25rem;
  }
  .slide-info .heading-description ul li{
    font-size: 17px;
  }
  .hero .slick-dots{
    bottom: 212px !important;
    padding-left: 280px !important;
  }
  /* Empresa */
  .la-empresa{
    padding-top: 12rem;
  }
  .text-empresa{
    padding-right: 13rem;
  }
  .card-empresa .title{
    padding: 4rem 7.5rem;
  }
  .card-empresa{
    margin-top: -18rem;
  }
  /* Trabajos y Servicios */
  .work article{
    height: 475px;
  }
  .work article .work--info{
    padding-top: 130px;
  }
}