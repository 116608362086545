/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a{
  color: inherit;
}
a:hover{
  color: inherit;
}
p{
  margin-bottom: 10px;
}

/* Globals */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700;900&display=swap');
@font-face {
  font-family: 'Gotham Light';
  src:  url(./fonts/Gotham-Light.otf),
        url(./fonts/Gotham-Light.ttf),
        url(./fonts/Gotham-Light.woff);
}
@font-face {
  font-family: 'Gotham Book';
  src:  url(./fonts/Gotham-Book.otf),
        url(./fonts/Gotham-Book.ttf),
        url(./fonts/Gotham-Book.woff);
}
@font-face {
  font-family: 'Gotham Bold';
  src:  url(./fonts/GothamBold.otf),
        url(./fonts/GothamBold.ttf),
        url(./fonts/GothamBold.woff);
}
body{
  font-family: "Titillium Web", Sans-serif;
  line-height: 1.5;
}
body.hidden{
  overflow: hidden;
}
/* .container{
  overflow-x: hidden;
} */
.bg-gray{
  background-color: #F4F8FD;
}
section h2.title{
  font-size: 2.25rem;
}
section h3.title{
  font-size: 1.5rem;
}
article .heading-title p{
  font-size: 18px;
}
.f-t{
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
}
.f-t-light{
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
}
.f-g-light{
  font-family: 'Gotham Light';
}
.f-g-book{
  /* font-family: 'Gotham Book'; */
  font-family: "Titillium Web", Sans-serif;
}
.f-g-bold{
  /* font-family: 'Gotham Bold'; */
  font-family: "Titillium Web", Sans-serif;
}
.btn-primary{
  background-color: transparent;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active{
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.btn-secundary{
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 2px;
  border-radius: 18px;
  letter-spacing: 2px;
}
.btn-secundary:hover{
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active{
  background-color: transparent !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: none !important;
}
.c-pointer{
  cursor: pointer;
}
.desktop{
  display: none;
}
.mobile{
  display: block;
}

/* Navbar */
header{
  background-color: #a59f9a;
  color: #FFFFFF;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
header > .container{
  overflow: visible;
}
header .navbar {
  padding: 0 0 0 0;
}
.navbar-collapse{
  padding-top: 50px;
}
header .navbar-brand{
  margin-right: 0;
  font-size: 1rem;
  padding-top: 0;
  padding-bottom: 0;
}
header .navbar-brand img{
  display: block;
}
header .nav-link{
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
}
header #navbarSupportedContent .socials{
  display: flex;
}
/* Icono */
header .burger {
  padding-top: 16px;
  padding-bottom: 15px;
  padding-left: 0;
}
header .burger .nav-button {
  position: relative;
  z-index: 1020;
  display: block;
  width: 30px;
  line-height: 1;
  margin-left: auto;
}

header .burger .nav-button #nav-icon3 {
  width: 24px;
  height: 17px;
  display: inline-block;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
header .burger .nav-button #nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
header .burger .nav-button #nav-icon3 span:nth-child(1) {
  top: 0;
}
header .burger .nav-button #nav-icon3 span:nth-child(2) {
  top: 8px;
}
header .burger .nav-button #nav-icon3 span:nth-child(3) {
  top: 8px;
}
header .burger .nav-button #nav-icon3 span:nth-child(4) {
  top: 16px;
}
header .burger.nav-open #nav-icon3 span:nth-child(1) {
  top: 9px;
  width: 0;
  left: 50%;
}
header .burger.nav-open #nav-icon3 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
header .burger.nav-open #nav-icon3 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
header .burger.nav-open #nav-icon3 span:nth-child(4) {
  top: 9px;
  width: 0;
  left: 50%;
}
.socials{
  display: flex;
}
.socials li{
  margin-left: 10px;
  margin-right: 10px;
}

/* Hero */
.hero{
  padding-top: 79px;
}
.item-carousel{
  position: relative;
  overflow: hidden;
}
.item-carousel .item-img{
  width: 280%;
  margin-left: -90%;
}
.slide-info .heading-title{
  height: 140px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.slick-slide{
  line-height: 0;
}
.slide-info{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  color: #FFFFFF;
  padding-top: 15rem;
  padding-bottom: 4rem;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: top;
  background-position-x: right;
}
.slide-info .heading-title h1{
  font-size: 2.25rem;
}
.slide-info .heading-title h1 strong{
  font-weight: 700;
}
.slide-info .heading-description ul{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.slide-info .heading-description ul li{
  line-height: 1.5;
  font-family: "Gotham Light", sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.hero .slick-dots{
  bottom: 65px !important;
  padding-left: 70px !important;
  text-align: left !important;
}
.hero .slick-dots li{
  width: 10px !important;
  height: 10px !important;
  margin: 0 2px !important;
}
.hero .slick-dots li button:before{
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  color: #ffffff !important;
}
/* La Empresa */
.la-empresa{
  padding-top: 4rem;
  padding-bottom: 3rem;
}
.text-empresa{
  /* font-family: 'Gotham Book'; */
  font-family: 'Titillium Web';
  padding-bottom: 2rem;
}
.card-empresa{
  background-color: #A59F9A;
  color: #FFFFFF;
}
.card-empresa .title{
  padding: 2rem 2.25rem;
  font-weight: 300;
}
.card-empresa .title strong{
  font-weight: 700;
}
.card-empresa img{
  width: 100%;
  display: block;
}
/* Productos y Servicios */
.productos-y-servicios{
  background-color: #3b424c;
  padding-top: 5rem;
  padding-bottom: 10rem;
}
.productos-y-servicios .title{
  color: #a59f9a;
  margin-bottom: 2rem;
}
.productos-y-servicios p{
  color: #FFFFFF;
}
.underline{
  position: relative
}
.underline::after{
  position: absolute;
  content: '';
  bottom: -17px;
  width: 24px;
  left: calc(50% - 12px);
  height: 1.55px;
  background-color: #FFFFFF;
}
.content-works{
  margin-top: -9rem;
  padding-bottom: 4rem;
}
.content-works .handle-scroll{
  overflow: scroll;
}
.works{
  width: 500%;
}
.work article{
  height: 370px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 30px;
}
.work article .work--info{
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  color: #FFFFFF;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8));
  padding-top: 90px;
}
.work article .work--info h3{
  letter-spacing: 4px;
}
.work article .work--info.underline::after{
  bottom: 0;
  width: 60%;
  left: 20%;
  height: 5px;
  background-color: #a59f9a;
}
.content-works .col-lg-10.underline::after{
  width: 0;
  height: 0;
}
.popup{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  display: none;
  position: fixed;
  overflow-y: auto;
  background: rgba(165,159,154,.98);
}
.popup .close{
  position: absolute;
  z-index: 1050;
  border-radius: 50%;
  right: 25px;
  top: 25px;
  cursor: pointer;
  color: #fff;
  text-shadow: none;
  padding: 2px 11px 7px 11px;
  opacity: 1;
  font-weight: 300;
  font-family: "Gotham Light", Sans-serif;
}
.popup.active{
  display: flex;
}
.popup .container{
  margin-top: auto;
  margin-bottom: auto;
}
.popup .categories{
  display: flex;
  overflow: auto;
}
.popup .categories .cat{
  color: #cccccc;
  text-transform: uppercase;
  font-family: 'Titillium Web';
  letter-spacing: 2px;
  margin-bottom: 15px;
  font-size: 15px;
  cursor: pointer;
  margin-right: 15px;
}
.popup .categories .cat.active{
  font-weight: 700;
  color: #FFFFFF;
}
.popup .content-gallery{
  margin-bottom: 50px;
}
.popup .content-gallery .slick-dots li{
  margin: 0 !important;
}
.popup .content-gallery .slick-dots li button:before{
  font-size: 8px !important;
  color: white !important;
}
.popup .content-gallery .slick-dots li.slick-active button:before{
  color: white !important;
}
.popup .algo--header .title{
  color: #3b424c;
  margin-bottom: 10px;
}
.popup .algo--body{
  padding-right: 0;
}
.popup .algo--body p{
  color: #FFFFFF;
  line-height: 1.2;
}
.popup .algo--body p strong{
  font-weight: 700;
}
.popup .btn-work{
  background-color: #3b424c;
  color: #FFFFFF;
  letter-spacing: 2px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0.375rem;
  border-radius: 7px;
}
.popup .slick-track{
  display: flex;
}
.popup .slick-list{
  max-height: 40vh;
  margin-top: auto;
  margin-bottom: auto;
}
.popup .slick-slide{
  max-height: 40vh;
  margin-top: auto;
  margin-bottom: auto;
}
.popup .slick-prev, .popup .slick-next{
  width: 25px;
  height: 25px;
  z-index: 1;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.popup .slick-prev::before, .slick-next::before{
  display: none;
}
.popup .slick-prev{
  left: -30px;
  background-image: url('http://bravosolucionesenmadera.tk/images/bravo-arrow-down.svg') !important;
  background-size: contain !important;
  transform: rotate(90deg);
}
.popup .slick-next{
  right: -30px;
  background-image: url('http://bravosolucionesenmadera.tk/images/bravo-arrow-down.svg') !important;
  background-size: contain !important;
  transform: rotate(-90deg);
}
.popup .carousel-item .img-fluid{
  max-height: 40vh;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}
/* Featured Phrase */
/* Featured Phrase */
.featured-phrase{
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-position: -200px center;
}
.featured-phrase h4{
  color: #FFFFFF;
  font-size: 1.25rem;
}
.featured-phrase{
  border-bottom: 10px solid #a59f9a; 
}
/* Contacto */
.contacto{
  background-color: #3b424c;
  padding-top: 5rem;
}
.contacto{
  color: #ffffff;
}
.contacto a .fab{
  color: #a59f9a;
}
.contacto .title{
  color: #a59f9a;
  margin-bottom: 2rem;
}
.content-form{
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 4rem;
}
.content-form form{
  background-color: #FFFFFF;
  padding: 40px 40px;
}
.content-form form .form-control{
  border: 0;
  border-radius: 0 ;
  border-bottom: 1px solid #a59f9a;
  padding-left: 0;
  color: #3e3e43;
}
.form-control::-webkit-input-placeholder { /* Edge */
  color: #3e3e43;
}

.form-control:-ms-input-placeholder { /* Internet Explorer */
  color: #3e3e43;
}

.form-control::placeholder {
  color: #3e3e43;
}
.content-form form .btn-form{
  background-color: #a59f9a;
  width: 100%;
  text-transform: uppercase;
  color: #FFFFFF;
  letter-spacing: 3px;
  font-weight: 300;
  border-radius: 25px;
  margin-top: 30px;
}
.alert{
  display: none;
}
.alert.show{
  display: block;
}
/* Footer */
footer{
  padding-top: 13rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}